import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import AOS from "aos";
import "aos/dist/aos.css"
Vue.config.productionTip = false

new Vue({
  theme: {
    primary: '#FFA726'
  },
  created() {
    this.$vuetify.theme.primary = '#FFA726',
      AOS.init({
        // Global settings:
        //offset: -150, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms

        once: true, // whether animation should happen only once - while scrolling down
        anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
      })
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
