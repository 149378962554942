export const whatsAppStringFormatted = '0176 4042 0274';
export const whatsAppStringPlain = '4917640420274';
export const phoneStringFormatted = '0176 4042 0274';
export const phoneStringPlain = '4917640420274';
export const email = 'info@armaturenfritz.de';
export const owner = 'Lukas Kubasek';
export const addressName = 'Armaturen Fritz';
export const addressStreet = 'Ludwigstr. 9b';
export const addressZipCity = '76768 Berg';
export const addressCountry = 'Deutschland';
export const taxId = 'DE246038888';




