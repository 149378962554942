<template>
  <div class="servicesHeader">
    <v-layout column justify-center align-center>
      <div
        :class="
          $vuetify.breakpoint.smAndDown ? 'text-h5 mt-2' : 'text-h4 mt-12'
        "
        class="font-weight-bold align-self-center align-center"
      >
        UNSERE
        <span class="primary--text">DIENSTLEISTUNGEN</span>
      </div>
      <v-layout justify-center align-center>
        <v-flex lg4 mt-4>
          <v-container class="text-center">
            Armaturen Fritz bietet Ihnen die komplette Fertigmontage im Bad an.
            Ob Duschkabine, Duschgarnitur, Waschtisch mit Spiegelschrank,
            Badheizkörper oder WC.
          </v-container>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "ServicesHeader",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>