<template>
  <div class="impressum">
    <v-main class="bg">
      <v-layout fill-height column>
        <v-flex> <Toolbar /> <ImpressumContent /> </v-flex>
      </v-layout>
    </v-main>
  </div>
</template>
<script>
import Toolbar from "@/components/global/Toolbar";
import ImpressumContent from "@/components/impressum/ImpressumContent";
export default {
  name: "Impressum",
  components: { Toolbar, ImpressumContent },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Impressum",
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
.bg {
  background-image: url("../assets/background_full.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
</style>

  