<template>
  <v-container fluid fill-height>
    <v-layout fill-height column justify-space-between>
      <v-layout align-self-center align-center justify-center>
        <v-layout mb-12 column justify-center>
          <!--           <div
            :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'"
            class="
              mb-12
              white--text
              font-weight-medium
              justify-center
              align-self-center align-center
            "
          >
            SEIT 2022
          </div> -->
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h1'"
            class="
              white--text
              font-weight-medium
              text-center
              align-self-center align-center
            "
          >
            MONTAGE ZU FESTPREISEN
          </div>
          <v-layout row justify-center>
            <v-flex xs10 lg5>
              <div
                :class="$vuetify.breakpoint.smAndDown ? '' : 'text-h6'"
                class="my-12 font-weight-medium white--text text-center"
              >
                Wir bauen Ihre Armatur zu Pauschalpreisen ein. Rufen Sie an oder
                senden Sie uns ein Bild von der auszutauschenden Armatur mit
                Ihrer Adresse per WhatsApp an {{ whatsAppStringFormatted }}. Sie
                erhalten innerhalb von 24 Stunden unser Angebot.
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-layout>

      <v-container>
        <v-layout column justify-end>
          <v-spacer></v-spacer>
          <div>
            <v-layout row>
              <v-spacer></v-spacer>
              <div>
                <v-img
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-6'"
                  contain
                  :max-height="$vuetify.breakpoint.smAndDown ? '64' : '96'"
                  :src="require('../../assets/WhatsAppNew.svg')"
                ></v-img>
              </div>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3'"
                class="align-self-center font-weight-medium"
              >
                WhatsApp
              </div>
            </v-layout>
            <v-layout justify-end>
              <a
                :class="$vuetify.breakpoint.smAndDown ? 'text-h6' : 'text-h5'"
                target="_blank"
                :href="'https://wa.me/' + whatsAppStringPlain"
                class="align-self-center font-weight-medium"
              >
                {{ whatsAppStringFormatted }}
              </a>
            </v-layout>
          </div>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>
<script>
import {
  whatsAppStringFormatted,
  whatsAppStringPlain,
} from "@/config/config.js";
export default {
  name: "FrontLanding",
  components: {},
  data() {
    return {
      whatsAppStringFormatted: whatsAppStringFormatted,
      whatsAppStringPlain: whatsAppStringPlain,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>