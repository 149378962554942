<template>
  <div class="contact">
    <v-main class="bg">
      <v-layout fill-height column>
        <v-flex> <Toolbar /> <ContactContent /> </v-flex>
        <v-flex> </v-flex>
      </v-layout>
    </v-main>
  </div>
</template>
<script>
import Toolbar from "@/components/global/Toolbar";
import ContactContent from "@/components/contact/ContactContent";
export default {
  name: "Contact",
  components: { Toolbar, ContactContent },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Kontakt",
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
.bg {
  background-image: url("../assets/background_full.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
</style>