<template>
  <div class="climaxServices">
    <div>
      <v-container mb-12>
        <v-layout
          my-12
          justify-center
          align-center
          :class="$vuetify.breakpoint.smAndDown ? 'column' : ''"
        >
          <v-flex
            xs9
            lg2
            :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : 'mr-6'"
          >
            <v-card elevation="0" class="grey lighten-4" height="196">
              <v-layout fluid fill-height class="align-center">
                <div class="pa-4">
                  <div class="mb-4 text-h5 font-weight-medium">ARMATUREN</div>
                  <div>
                    Sie kaufen sich Ihre Wunscharmatur für Sanitärbereich oder
                    Küche und wir bauen diese fachgerecht zu Pauschalpreisen für
                    Sie ein.
                  </div>
                </div>
              </v-layout>
            </v-card>
          </v-flex>
          <!--  <v-flex xs9 lg2>
            <v-card elevation="0" color="#2680FF" height="270">
              <v-layout fluid fill-height class="align-center">
                <div class="pa-4">
                  <div class="mb-4 text-h5 font-weight-medium white--text">
                    KOMPLETTBAD
                  </div>
                  <div class="white--text">
                    Auf Anfrage machen wir Ihnen auch ein Angebot für Ihr
                    komplettes Badezimmer.
                  </div>
                </div>
              </v-layout>
            </v-card>
          </v-flex> -->
          <v-flex
            class="grey lighten-4"
            xs9
            lg2
            :class="$vuetify.breakpoint.smAndDown ? 'mx-6' : 'ml-6'"
          >
            <v-card elevation="0" class="grey lighten-4" height="196">
              <v-layout fluid fill-height class="align-center">
                <div class="pa-4">
                  <div class="mb-4 text-h5 font-weight-medium">SONSTIGES</div>
                  <div>
                    Sie haben einen Kleinauftrag und finden keinen Handwerker!
                    Die Firma Armaturenfritz erledigt auch Aufträge, die nicht
                    in unseren Dienstleistungen beschrieben sind. Sprechen Sie
                    uns darauf an!
                  </div>
                </div>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClimaxServices",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>