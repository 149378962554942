<template>
  <div class="aboutUsSmallScreens">
    <v-layout column justify-center align-center>
      <div class="text-h5 mt-2 font-weight-bold align-self-center align-center">
        ÜBER
        <span class="primary--text">ARMATUREN FRITZ</span>
      </div>
      <v-layout justify-center align-center>
        <v-flex lg4 mt-4>
          <v-container class="text-center">
            Wir sind ein Unternehmen, das sich gerne um Ihre Aufträge kümmert.
            Für das Aufgabenfeld Armaturenmontage steht die Firma Armaturenfritz
            für innovative Abwicklung Ihrer Aufträge. Hier können Sie auf
            qualifiziertes Fachpersonal mit langjähriger Berufserfahrung
            vertrauen.
          </v-container>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: "AboutUsSmallScreens",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>