<template>
  <v-layout
    justify-center
    :class="$vuetify.breakpoint.mdAndDown ? '' : 'my-12'"
  >
    <v-flex xs12 md8 lg5>
      <v-card
        class="pa-10"
        :elevation="$vuetify.breakpoint.mdAndDown ? '0' : ''"
        :class="$vuetify.breakpoint.mdAndDown ? 'rounded-0' : ''"
      >
        <v-card-title class="justify-center mb-5"> Impressum </v-card-title>

        <br />
        <v-simple-table color="rgb(255, 255, 255, 0.7)" class="my-5">
          <template v-slot:default>
            <tbody>
              <tr class="pt-4">
                <td>
                  <div>Adresse</div>
                </td>
                <td>
                  <div>{{ addressName }}</div>
                  <div>{{ addressStreet }}</div>
                  <div>{{ addressZipCity }}</div>
                  <div>{{ addressCountry }}</div>
                </td>
              </tr>
              <tr class="pt-4">
                <td>
                  <div>Telefon</div>
                </td>
                <td>
                  <a :href="'tel:+' + phoneStringPlain">
                    {{ phoneStringFormatted }}</a
                  >
                </td>
              </tr>

              <tr class="pt-4">
                <td>
                  <div>Inhaber</div>
                </td>
                <td>{{ owner }}</td>
              </tr>
              <tr class="pt-4">
                <td>
                  <div>Email</div>
                </td>
                <td>
                  <a :href="'mailto:' + email">{{ email }}</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--        <div class="font-weight-bold caption">Steueridentifikation</div>
        <div class="caption">USt-IdNr.: {{ taxId }}</div> -->
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import {
  email,
  phoneStringFormatted,
  phoneStringPlain,
  owner,
  addressName,
  addressStreet,
  addressZipCity,
  addressCountry,
  taxId,
} from "@/config/config.js";
export default {
  name: "ContactContent",
  components: {},
  data() {
    return {
      email: email,
      phoneStringFormatted: phoneStringFormatted,
      phoneStringPlain: phoneStringPlain,
      owner: owner,
      addressName: addressName,
      addressStreet: addressStreet,
      addressZipCity: addressZipCity,
      addressCountry: addressCountry,
      taxId: taxId,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>