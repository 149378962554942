<template>
  <div class="home">
    <v-main>
      <v-img
        eager
        :height="$vuetify.breakpoint.height"
        :src="require('@/assets/background_low.jpg')"
      >
        <v-layout fill-height column>
          <Toolbar />
          <FrontLanding />
        </v-layout>
      </v-img>
      <ClimaxPoints />

      <ServicesHeader
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      />

      <ClimaxServices
        data-aos="flip-left"
        data-aos-duration="1000"
        data-aos-delay="300"
      />
      <v-card
        elevation="0"
        :height="$vuetify.breakpoint.smAndDown ? '0' : '120'"
      >
      </v-card>

      <AboutUsSmallScreens v-if="$vuetify.breakpoint.smAndDown" />
      <AboutUsBigScreens v-if="!$vuetify.breakpoint.smAndDown" />
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Toolbar from "@/components/global/Toolbar";
import ClimaxPoints from "@/components/home/ClimaxPoints";
import ClimaxServices from "@/components/home/ClimaxServices";
import FrontLanding from "@/components/home/FrontLanding";
import ServicesHeader from "@/components/home/ServicesHeader";
import AboutUsSmallScreens from "@/components/home/AboutUsSmallScreens";
import AboutUsBigScreens from "@/components/home/AboutUsBigScreens";

export default {
  name: "Home",
  components: {
    Toolbar,
    ClimaxPoints,
    ClimaxServices,
    FrontLanding,
    ServicesHeader,
    AboutUsSmallScreens,
    AboutUsBigScreens,
  },
  metaInfo: {
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
};
</script>
<style  >
a {
  text-decoration: none;
}
</style>