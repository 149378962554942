<template>
  <v-layout
    justify-center
    :class="$vuetify.breakpoint.mdAndDown ? '' : 'my-12'"
  >
    <v-flex xs12 md8 lg5>
      <v-card
        class="pa-10"
        :elevation="$vuetify.breakpoint.mdAndDown ? '0' : ''"
        :class="$vuetify.breakpoint.mdAndDown ? 'rounded-0' : ''"
      >
        <v-card-title class="justify-center mb-5"> Kontakt </v-card-title>

        <div class="text-center">
          Schreiben Sie uns eine WhatsApp und erhalten ein Angebot innerhalb
          24H!
        </div>

        <v-layout mt-6 column>
          <v-layout justify-center>
            <v-flex lg12 md6>
              <v-card>
                <v-card-text>
                  <v-icon> mdi-whatsapp </v-icon>
                  <span class="ml-4">
                    <a
                      target="_blank"
                      :href="'https://wa.me/' + whatsAppStringPlain"
                    >
                      {{ whatsAppStringFormatted }}
                    </a></span
                  ></v-card-text
                >
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout justify-center>
            <v-flex lg12 md6>
              <v-card>
                <v-card-text>
                  <v-icon> mdi-email </v-icon>
                  <span class="ml-4"
                    ><a :href="'mailto:' + email">{{ email }}</a></span
                  ></v-card-text
                >
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout justify-center>
            <v-flex lg12 md6>
              <v-card>
                <v-card-text>
                  <v-icon> mdi-phone </v-icon>
                  <span class="ml-4"
                    ><a :href="'tel:+' + phoneStringPlain">
                      {{ phoneStringFormatted }}</a
                    ></span
                  ></v-card-text
                >
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
        <div class="mt-12">
          <div class="text-center font-weight-bold">
            Scannen und direkt zum WhatsApp-Chat!
          </div>
          <v-layout justify-center>
            <v-img max-width="300" :src="require('@/assets/wa_qr.svg')">
            </v-img>
          </v-layout>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import {
  email,
  whatsAppStringFormatted,
  whatsAppStringPlain,
  phoneStringFormatted,
  phoneStringPlain,
} from "@/config/config.js";
export default {
  name: "ContactContent",
  components: {},
  data() {
    return {
      email: email,
      whatsAppStringFormatted: whatsAppStringFormatted,
      whatsAppStringPlain: whatsAppStringPlain,
      phoneStringFormatted: phoneStringFormatted,
      phoneStringPlain: phoneStringPlain,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>