<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Armaturen Montage zu Pauschalpreisen - Armaturen, Komplettbad und sonstige Kleinarbeiten - Angebot in 24 Stunden",
      },
    ],
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Armaturen Fritz",
    // all titles will be injected into this template
    titleTemplate: "%s - Armaturen Fritz",

    description:
      "Armaturen Montage zu Pauschalpreisen - Armaturen, Komplettbad und sonstige Kleinarbeiten - Angebot in 24 Stunden",
  },

  data() {
    return {
      maintenance: false,
      sheet: false,
    };
  },
};
</script>
<style scoped>
</style>