<template>
  <v-app-bar :height="toolbarHeight" color="rgb(255, 255, 255, 0.6)">
    <v-layout
      align-center
      :class="$vuetify.breakpoint.smAndDown ? 'column' : ''"
    >
      <v-flex xs6 md6 lg4>
        <v-img
          eager
          contain
          :max-height="$vuetify.breakpoint.smAndDown ? '48' : '76'"
          :src="require('../../assets/logo.svg')"
        ></v-img>
      </v-flex>
      <v-flex xs6 md6 lg8>
        <v-layout
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'justify-center'"
        >
          <div
            v-for="item in menuItems"
            :key="item.text"
            :class="$vuetify.breakpoint.smAndDown ? 'mr-4' : 'mr-12'"
          >
            <v-btn :to="item.route" text color="black">
              <span>{{ item.text }}</span>
            </v-btn>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      toolbarHeight: 108,
      menuItems: [
        { text: "Home", route: "/" },
        { text: "Kontakt", route: "/contact" },
        { text: "Impressum", route: "/imprint" },
      ],
    };
  },
  props: {
    linkdata: Array,
  },
};
</script>

<style scoped>
</style>