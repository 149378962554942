import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Impressum from '../views/Impressum.vue'
Vue.use(VueRouter)
Vue.use(VueMeta)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Armaturen Fritz' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Kontakt - Armaturen Fritz' }
  },
  {
    path: '/imprint',
    name: 'Impressum',
    component: Impressum,
    meta: { title: 'Impressum - Armaturen Fritz' }
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  next()
});
export default router

