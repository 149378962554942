<template>
  <div class="climaxPoints">
    <div class="grey lighten-5">
      <v-container my-12>
        <v-layout
          my-12
          justify-center
          :class="$vuetify.breakpoint.smAndDown ? 'column' : ''"
        >
          <v-flex
            xs12
            lg3
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : 'mr-12'"
          >
            <v-layout>
              <div>
                <v-img
                  eager
                  contain
                  max-height="64"
                  :src="require('../../assets/rating_icon.svg')"
                ></v-img>
              </div>
              <div>
                <div class="mb-4 text-h5 font-weight-medium">
                  TOP ZUFRIEDENHEIT
                </div>
                <div>
                  Unsere Monteure legen höchsten Wert auf Ordnung und
                  Sauberkeit! Kundenzufriedenheit steht bei uns an erster
                  Stelle!
                </div>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg3 :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : ''">
            <v-layout>
              <div>
                <v-img
                  eager
                  contain
                  max-height="64"
                  :src="require('../../assets/fast_icon.svg')"
                ></v-img>
              </div>
              <div>
                <div class="mb-4 text-h5 font-weight-medium">
                  ANGEBOT IN 24H
                </div>
                <div>
                  Sie erhalten innerhalb von 24 Stunden ein Angebot! Schicken
                  Sie uns dazu einfach eine WhatsApp-Nachricht mit Ihrer Adresse
                  und einem Bild der auszutauschenden Armatur.
                </div>
              </div>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            lg3
            :class="$vuetify.breakpoint.smAndDown ? 'mb-6' : 'ml-12'"
          >
            <v-layout>
              <div>
                <v-img
                  eager
                  contain
                  max-height="64"
                  :src="require('../../assets/price_icon.svg')"
                ></v-img>
              </div>
              <div>
                <div class="mb-4 text-h5 font-weight-medium">
                  GERINGE KOSTEN
                </div>
                <div>
                  Wir berechnen nur die Montage und die Anfahrt. Keine
                  unerwarteten Zusatzkosten.
                </div>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClimaxPoints",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>