<template>
  <div class="aboutUsBigScreens">
    <v-img :src="require('@/assets/bottom_img.png')">
      <v-layout>
        <v-flex lg2></v-flex>
        <v-flex ml-12 lg5>
          <div
            class="
              text-h4
              mt-12
              ml-12
              mt-12
              text-h4
              font-weight-bold
              align-self-center align-center
            "
          >
            ÜBER
            <span class="primary--text">ARMATUREN FRITZ</span>
          </div>

          <v-layout ml-12 mt-6>
            Wir sind ein Unternehmen, das sich gerne um Ihre Aufträge kümmert.
            Für das Aufgabenfeld Armaturenmontage steht die Firma Armaturenfritz
            für innovative Abwicklung Ihrer Aufträge. Hier können Sie auf
            qualifiziertes Fachpersonal mit langjähriger Berufserfahrung
            vertrauen.
          </v-layout>
        </v-flex></v-layout
      >
    </v-img>
  </div>
</template>
<script>
export default {
  name: "AboutUsBigScreens",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style>
</style>